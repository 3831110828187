<template>
  <div class="gallery">
    <figure class="gallery__preview-container">
      <img :src="currentImage" class="gallery__preview-image" />
    </figure>

    <ul class="gallery__thumbnails">
      <li class="gallery__thumbnail" v-for="(src, index) in items" :key="index" :class="{ 'is-active': currentImage === src }">
        <button @click="onClickThumbnail(src)" class="gallery__thumbnail-btn">
          <img :src="src" class="gallery__thumbnails-image" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Gallery',

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      currentImage: []
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.items.length) {
        this.currentImage = this.items[0];
      }
    },

    onClickThumbnail(item) {
      this.currentImage = item;
    }
  },

  watch: {
    items() {
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped>
.gallery {
  &__preview-container {
    margin: 0;
  }

  &__preview-image {
    border: 1px solid $c-light-gray;
    max-width: 100%;
  }

  &__thumbnail-btn {
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
  }

  &__thumbnails {
    display: flex;
    margin: 1rem 0 0;
    padding-left: 0;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__thumbnail {
    list-style: none;
    margin-left: 0.8rem;

    flex: 1 0 75vw;
    max-width: 10rem;

    &:first-child {
      margin-left: 0;
    }

    &.is-active {
      img {
        border-color: $c-brand;
        border-width: 4px;
      }
    }
  }

  &__thumbnails-image {
    border: 1px solid $c-medium-gray;
    height: 120px;
    max-width: 100%;
  }
}
</style>
